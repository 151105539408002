import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import ContactUs from "../components/ContactUs/ContactUs"

const ContactUsPage = () => (
  <SiteLayout>
    <SEO title="Contact us" />
    <ContactUs />
  </SiteLayout>
)

export default ContactUsPage
