import React, { useState } from "react"
import classNames from "classnames"

// eslint-disable-next-line no-unused-vars
import contactUsEmailAddress from "../../images/contact-us-email-address-black.png"
import styles from "./ContactUs.module.css"
import pinmap from "../../images/pinmap.png"
import LoadingOverlay from "react-loading-overlay"
import styled, { css } from "styled-components"
import ReCAPTCHA from "react-google-recaptcha"

const DarkBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  ${props =>
    props.disappear &&
    css`
      display: none; /* show */
    `}
`

const ContactUs = () => {
  const [fname, setFname] = useState("")
  const [lname, setLname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [title, setTitle] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isValidToSubmit, setValidToSubmit] = useState(false)

  const handleSubmit = () => {
    setIsLoading(true)

    const myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded")

    const formData = new URLSearchParams()
    formData.append("fname", fname)
    formData.append("lname", lname)
    formData.append("email", email)
    formData.append("phone", phone)
    formData.append("message", message)
    formData.append("title", title)

    fetch(`${process.env.GATSBY_API_URL}contactUs`, {
      method: "POST",
      body: formData,
      headers: myHeaders,
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setTimeout(function() {
          setIsLoading(false)

          if (data) {
            if (data.success) {
              window.location.href = "/contact-us/thankyou"
            } else {
              alert("Sorry, an error occured during your request.")
            }
          }
        }, 1000)
      })
  }
  return (
    <div className={styles.sectionWrapper}>
      <DarkBackground disappear={!isLoading}>
        <LoadingOverlay
          active={true}
          spinner={true}
          text="Processing..."
        ></LoadingOverlay>
      </DarkBackground>
      <h2 className={styles.title}>Contact us</h2>
      <div className={classNames("container", styles.container)}>
        <p>
          <img className={styles.iconpin} alt="Pin Map Icon" src={pinmap} />
          Lvl 1, 582 Queensberry Street, North Melbourne VIC 3051
          {/* <h1>{newFname}</h1> */}
        </p>
        <div className={styles.block}>
          <p>Tell us how we can help - select from list below</p>
          <select
            className={styles.input}
            name="title"
            onChange={e => {
              setTitle(e.target.value)
            }}
          >
            <option value="">---[Please Select Topic]---</option>
            <option value="Advisory - General">Advisory - General</option>
            <option value="Reports">Reports</option>
            <option value="Member Insights">Member Insights</option>
            <option value="Suburb Growth">Suburb Growth</option>
            <option value="Location Score">Location Score</option>
            <option value="DSR Data">DSR Data</option>
            <option value="Sell or Hold">Sell or Hold</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className={`${styles.block} ${styles.columnB}`}>
          <input
            className={`${styles.input} ${styles.inputFirst}`}
            type="text"
            placeholder="First Name"
            name="fname"
            onChange={e => {
              // newFname = e.target.value
              setFname(e.target.value)
            }}
          />
          <input
            className={`${styles.input} ${styles.inputLast}`}
            type="text"
            placeholder="Last Name"
            name="lname"
            onChange={e => {
              setLname(e.target.value)
            }}
          />
        </div>
        <div className={`${styles.block} ${styles.columnB}`}>
          <input
            className={`${styles.input} ${styles.inputFirst}`}
            type="email"
            placeholder="Email"
            name="email"
            onChange={e => {
              setEmail(e.target.value)
            }}
          />
          <input
            className={`${styles.input} ${styles.inputLast}`}
            type="phone"
            placeholder="Phone"
            name="phone"
            onChange={e => {
              setPhone(e.target.value)
            }}
          />
        </div>
        <div className={styles.block}>
          <textarea
            placeholder="Message"
            name="message"
            onChange={e => {
              setMessage(e.target.value)
            }}
          ></textarea>
        </div>
        <div className={styles.block}>
          <ReCAPTCHA
            sitekey={process.env.RECAPTCHA_SITE_KEY}
            onChange={() => {
              setValidToSubmit(true)
            }}
          />
        </div>
        {isValidToSubmit && (
          <div className={styles.block}>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ContactUs
